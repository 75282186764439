import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <header className={`header ${!visible ? "hidden" : ""}`}>
      <div className="logo">
        <Link to="/" aria-label="Portfolio Home">
          <img src="/images/portfolio-logo.png" alt="Portfolio Logo" />
        </Link>
      </div>
      <Nav visible={visible} />
    </header>
  );
};

export default Header;

import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const BlurGlass = ({ children }) => {
  AOS.init();
  return (
    <div className="blur-glass" data-aos="fade-up" data-aos-duration="1000">
      {children}
    </div>
  );
};

export default BlurGlass;

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BsPlus, BsDash } from "react-icons/bs";

const AccordionSection = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="reflection improvement">
      <motion.h3
        initial={false}
        animate={{ backgroundColor: isOpen ? "#22e592" : "#22e59215" }}
        whileHover={{ backgroundColor: "#22e592" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <BsDash className={`accordion-icon ${isOpen ? "open" : "closed"}`} />
        ) : (
          <BsPlus className={`accordion-icon ${isOpen ? "open" : "closed"}`} />
        )}
        {title}
      </motion.h3>
      <AnimatePresence>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.78] }}
            style={{ overflow: "hidden" }}
          >
            {content}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

const Accordion = ({ sections }) => {
  return (
    <div className="accordion">
      {sections.map((section, index) => (
        <AccordionSection
          key={index}
          title={section.heading}
          content={section.content}
        />
      ))}
    </div>
  );
};

export default Accordion;

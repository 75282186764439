import React, { useState, useEffect } from "react";
import { motion, useMotionValue } from "framer-motion";
import Square from "./Square";

const Stacks = ({ restData }) => {
  const developerStack = restData.acf.developer_stack;
  const gridSize = Math.ceil(developerStack.length / 3);
  const grid = Array.from({ length: gridSize }, (_, rowIndex) =>
    Array.from({ length: 3 }, (_, colIndex) => rowIndex * 3 + colIndex)
  );

  const size = 100;
  const [active, setActive] = useState({ row: 0, col: 0 });
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const [gap, setGap] = useState(calculateGap());

  useEffect(() => {
    const handleResize = () => {
      setGap(calculateGap());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="stacks">
      <motion.div
        animate={{ "--base-hue": 360 }}
        initial={{ "--base-hue": 0 }}
        transition={{ duration: 10, loop: Infinity, ease: "linear" }}
        style={{ width: "100%", height: "100%" }}
      >
        <motion.div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: (size + gap) * 3 - gap,
            position: "relative",
            perspective: 500,
          }}
        >
          {grid.map((row, rowIndex) =>
            row.map((cellIndex) => {
              if (cellIndex < developerStack.length) {
                return (
                  <Square
                    x={x}
                    y={y}
                    active={active}
                    setActive={setActive}
                    rowIndex={Math.floor(cellIndex / 3)}
                    colIndex={cellIndex % 3}
                    size={size}
                    gap={gap}
                    text={developerStack[cellIndex]}
                    key={cellIndex}
                  />
                );
              } else {
                return null;
              }
            })
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};

function calculateGap() {
  const screenWidth = window.innerWidth;
  let gapSize;
  if (screenWidth < 425) {
    gapSize = 5;
  } else if (screenWidth >= 425 && screenWidth < 768) {
    gapSize = 18;
  } else if (screenWidth >= 768 && screenWidth < 1024) {
    gapSize = 25;
  } else if (screenWidth >= 1024) {
    gapSize = 40;
  }
  return gapSize;
}

export { calculateGap };
export default Stacks;

import "./App.css";
import { AppRouter } from "./routers/AppRouter";
import AuroraBackground from "./components/AuroraBackground";

function App() {
  return (
    <div className="App">
      <AuroraBackground />
      <AppRouter />
    </div>
  );
}

export default App;

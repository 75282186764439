import React from "react";
import { motion } from "framer-motion";

const CopyEmailButton = ({ email }) => {
  const copyEmail = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        alert("Email copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying email to clipboard: ", error);
      });
  };

  return (
    <motion.div
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      transition={{
        type: "spring",
        stiffness: 400,
        damping: 17,
      }}
    >
      <button onClick={copyEmail} aria-label="Copy Email">
        Copy Email
      </button>
    </motion.div>
  );
};

export default CopyEmailButton;

import React, { useState, useEffect } from "react";
import { restBase } from "../global/globals";
import Loading from "../components/Loading";
import WorkItem from "../components/WorkItem";
import { Link } from "react-router-dom";
import { PAGE_NAME } from "../global/globals";
import BlurGlass from "../components/BlurGlass";

const Works = () => {
  const restPath = restBase + "portfolio-work?_embed&order=asc&orderby=date";
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    document.title = `${PAGE_NAME} | Works`;
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setTimeout(() => {
          setLoadStatus(true);
        }, 1600);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);
  return (
    <>
      {isLoaded ? (
        <div className="works-list">
          <Link to="/" className="logo-top" aria-label="Portfolio Logo">
            <img src="/images/portfolio-logo.png" alt="Portfolio Logo" />
          </Link>
          <h1>Works</h1>
          {restData.map((work) => (
            <BlurGlass key={work.id}>
              <WorkItem
                work={work}
                showLink={true}
                showDetails={false}
                showToolkits={true}
                pageType="works-page"
              />
            </BlurGlass>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default Works;

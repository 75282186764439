import React from "react";
import { motion } from "framer-motion";
import {
  logoPathCircle,
  logoPathfilled,
  logoPathAnotherfilled,
  logoPathCenter,
} from "../global/globals";

const AnimatedLogo = () => {
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 0.1 + i * 0.2;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 7, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };

  const logoWidth = 200;
  const logoHeight = 200;
  const viewBoxX = 0;
  const viewBoxY = 0;
  const viewBoxWidth = 900;
  const viewBoxHeight = 900;

  return (
    <div className="animation">
      <motion.svg
        width={logoWidth}
        height={logoHeight}
        viewBox={`${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`}
        initial="hidden"
        animate="visible"
        className={"logo-svg"}
      >
        <motion.path
          d={logoPathCircle}
          stroke="#22e592"
          strokeWidth="2"
          fill="#242230"
          variants={draw}
          custom={1}
        />
        <motion.path
          d={logoPathfilled}
          stroke="#22e592"
          strokeWidth="2"
          fill="#22e592"
          variants={draw}
          custom={3}
        />
        <motion.path
          d={logoPathAnotherfilled}
          stroke="#22e592"
          strokeWidth="2"
          fill="#22e592"
          variants={draw}
          custom={3}
        />
        <motion.path
          d={logoPathCenter}
          stroke="#22e592"
          strokeWidth="2"
          fill="#22e592"
          variants={draw}
          custom={2}
        />
      </motion.svg>
    </div>
  );
};

export default AnimatedLogo;

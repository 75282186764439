import React from "react";
import { motion } from "framer-motion";
import { FaLinkedin, FaGithubSquare, FaEnvelopeSquare } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const socialLinks = [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/kaiasun/",
      icon: FaLinkedin,
    },
    {
      name: "GitHub",
      url: "https://github.com/ksskyy",
      icon: FaGithubSquare,
    },
    {
      name: "Email",
      url: "kaiasunofficial@gmail.com",
      icon: FaEnvelopeSquare,
    },
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div id="social-links" className="social-links">
          {socialLinks.map((link, index) => (
            <motion.a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={link.name}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              <link.icon className="icon" />
            </motion.a>
          ))}
        </div>
        <p>© {currentYear} Kaia</p>
      </div>
    </footer>
  );
};

export default Footer;

import { useEffect, useState } from "react";
import { restBase, PAGE_NAME } from "../global/globals";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import MediaImage from "../components/MediaImage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../components/Banner";
import AboutList from "../components/AboutList";
import BlurGlass from "../components/BlurGlass";
import ScrollDown from "../components/ScrollDown";

const Home = () => {
  const restPath = restBase + "pages/13?_embed";
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    document.title = `${PAGE_NAME} | Portfolio | Home`;
    window.scrollTo(0, 0);
    fetch(restPath)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setTimeout(() => {
          setLoadStatus(true);
        }, 1600);
      })
      .catch((error) => console.error("Error:", error));
  }, [restPath]);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0",
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      {isLoaded ? (
        <div id={`post-${restData.id}`} className="home">
          <Link to="/" className="logo-top" aria-label="Portfolio Logo">
            <img src="/images/portfolio-logo.png" alt="Portfolio Logo" />
          </Link>
          <Banner />
          <div className="scroll-down-container">
            <ScrollDown />
          </div>
          <BlurGlass>
            <section className="home-works">
              <h2>Works</h2>
              {restData &&
              restData._embedded &&
              restData._embedded["acf:post"] ? (
                <Slider {...settings}>
                  {restData._embedded["acf:post"]
                    .slice(0, 3)
                    .map((post, index) => (
                      <div key={index} className="home-work">
                        <h3>
                          <Link to={`/works/${post.slug}`}>
                            {post.title.rendered}
                          </Link>
                        </h3>
                        {post.featured_media && (
                          <MediaImage mediaID={post.featured_media} />
                        )}
                      </div>
                    ))}
                </Slider>
              ) : (
                <p>No posts available</p>
              )}
            </section>
          </BlurGlass>
          <BlurGlass>
            <section className="home-about">
              {restData &&
              restData._embedded &&
              restData._embedded["acf:post"] ? (
                restData._embedded["acf:post"]
                  .slice(3, 4)
                  .map((post, index) => (
                    <div key={index}>
                      <h2>{post.title.rendered}</h2>

                      <AboutList
                        developerStack={post.acf.developer_stack}
                        currentlyLearning={post.acf.currently_learning}
                        isHomePage={true}
                        isAboutPage={false}
                      />

                      <motion.div
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 17,
                        }}
                      >
                        <Link to={`/about`}>
                          <button>INTERESTED</button>
                        </Link>
                      </motion.div>
                    </div>
                  ))
              ) : (
                <p>No posts available</p>
              )}
            </section>
          </BlurGlass>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Home;

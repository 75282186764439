import React, { useState, useCallback, useRef, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";
import styles from "./styles.module.css";

const Banner = () => {
  const ref = useRef([]);
  const [items, set] = useState([]);
  const transitions = useTransition(items, {
    from: {
      opacity: 0,
      height: 0,
      innerHeight: 0,
      transform: "perspective(600px) rotateX(0deg)",
      color: "#f5f5f5",
    },
    enter: [
      { opacity: 1, height: 100, innerHeight: 100 },
      { transform: "perspective(600px) rotateX(0deg)", color: "#22e592" },
      { transform: "perspective(600px) rotateX(0deg)" },
    ],
    leave: [
      { color: "#00ffd0" },
      { innerHeight: 0 },
      { opacity: 0, height: 0 },
    ],
    update: { color: "#f5f5f5" },
  });

  const reset = useCallback(() => {
    ref.current.forEach(clearTimeout);
    ref.current = [];
    set([]);
    ref.current.push(
      setTimeout(
        () =>
          set([
            "Hello",
            "I'm Kaia",
            "A front-end web developer ",
            "UI & UX designer ",
          ]),
        100
      )
    );
    ref.current.push(
      setTimeout(
        () => set(["Hello", "A front-end web developer ", "UI & UX designer "]),
        800
      )
    );
    ref.current.push(
      setTimeout(
        () =>
          set([
            "Hello",
            "I'm Kaia",
            "A front-end web developer ",
            "UI & UX designer ",
          ]),
        2000
      )
    );
  }, []);

  useEffect(() => {
    reset();
    return () => ref.current.forEach(clearTimeout);
  }, [reset]);

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        {transitions(({ innerHeight, ...rest }, item) => (
          <animated.div
            className={styles.transitionsItem}
            style={rest}
            onClick={reset}
          >
            <animated.div style={{ overflow: "hidden", height: innerHeight }}>
              {item}
            </animated.div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default Banner;

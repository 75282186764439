import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated as a } from "@react-spring/web";
import useMeasure from "react-use-measure";
import { Container, Title, Frame, Content, toggle } from "./StylesAbout";
import * as Icons from "./Icons";
import { motion } from "framer-motion";
import { FaRegPlusSquare } from "react-icons/fa";
import { PiHandWavingFill } from "react-icons/pi";
import { RiCodeBoxLine } from "react-icons/ri";
import { MdInterests } from "react-icons/md";
import { LuPencilRuler } from "react-icons/lu";
import { FaCoffee } from "react-icons/fa";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const WavingHand = () => {
  return (
    <motion.div
      animate={{
        rotate: [0, -30, 0],
        transition: {
          duration: 1,
          ease: "easeInOut",
          times: [0, 0.5, 1],
          repeat: Infinity,
        },
      }}
      style={{ display: "inline-block" }}
    >
      <PiHandWavingFill style={{ color: "#00ffd0", fontSize: "24px" }} />
    </motion.div>
  );
};

const Tree = React.memo(({ children, name, style, defaultOpen = false }) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const previous = usePrevious(isOpen);
  const [ref, { height: viewHeight }] = useMeasure();
  const { height, opacity, y } = useSpring({
    from: { height: 0, opacity: 0, y: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      y: isOpen ? 0 : 20,
    },
  });

  const Icon =
    Icons[`${children ? (isOpen ? "Minus" : "Plus") : "Close"}SquareO`];
  return (
    <Frame>
      <Icon
        style={{
          ...toggle,
          opacity: children ? 1 : 0.3,
        }}
        onClick={() => setOpen(!isOpen)}
      />
      <Title style={style}>{name}</Title>
      <Content
        style={{
          opacity,
          height: isOpen && previous === isOpen ? "auto" : height,
        }}
      >
        <a.div ref={ref} style={{ y }} children={children} />
      </Content>
    </Frame>
  );
});

const AboutList = ({
  developerStack,
  currentlyLearning,
  isHomePage,
  isAboutPage,
}) => {
  return (
    <Container>
      <Tree
        name={
          <>
            Hello <WavingHand /> Welcome to my website
          </>
        }
        defaultOpen
      >
        <Tree
          name={
            <>
              <span>My name is </span>
              <span style={{ color: "#22e592" }}>Kaia</span>
            </>
          }
        />

        <Tree
          name={
            <>
              A Front-end Web Developer
              <RiCodeBoxLine style={{ color: "#00ffd0" }} /> & UX Designer
              <LuPencilRuler style={{ color: "#00ffd0" }} />
            </>
          }
        />
        {isHomePage && (
          <>
            <Tree name="Developer Stacks" defaultOpen>
              {developerStack && developerStack.length > 0 && (
                <ul className="stacks">
                  {developerStack.map((tech, index) => (
                    <li key={index}>{tech}</li>
                  ))}
                </ul>
              )}
            </Tree>
            <Tree name="Currently Learning">
              {currentlyLearning && currentlyLearning.length > 0 && (
                <ul className="learning">
                  {currentlyLearning.map((learning, index) => (
                    <li key={index}>{learning}</li>
                  ))}
                </ul>
              )}
            </Tree>

            <Tree
              name={
                <>
                  Want to know more? Click <FaRegPlusSquare /> or "Interested"
                </>
              }
              style={{ color: "#22e592" }}
            >
              <Tree
                name={
                  <>
                    <span>Thank you for exploring more</span>
                  </>
                }
              />

              <Tree name="I'm an outdoor enthusiast">
                <Tree name={<span>love tennis, skiing, and hiking.</span>} />
              </Tree>
              <Tree name="I'm a big fun of movies and games" />
              <Tree
                name={
                  <>
                    <span style={{ color: "#22e592" }}>Let's chat </span>
                    <FaCoffee style={{ color: "#00ffd0" }} />
                  </>
                }
              />
            </Tree>
          </>
        )}
        {isAboutPage && (
          <>
            <Tree
              name={
                <>
                  Want to know more? Click{" "}
                  <FaRegPlusSquare style={{ color: "#f5f5f5" }} />
                </>
              }
              style={{ color: "#22e592" }}
            >
              <Tree name="I specialize in web development">
                <Tree
                  name={
                    <>
                      <span>I utilize </span>
                      <span style={{ color: "#22e592" }}>
                        HTML5, CSS, JavaScript, React, PHP and WordPress
                      </span>
                    </>
                  }
                />
                <Tree
                  name={
                    <>
                      <span>I can develop </span>
                      <span style={{ color: "#22e592" }}>
                        responsive websites{" "}
                      </span>
                      <span>from scratch</span>
                    </>
                  }
                />
                <Tree
                  name={
                    <>
                      <span>with modern </span>
                      <span style={{ color: "#22e592" }}>user-friendly </span>
                      <span>web experience</span>
                    </>
                  }
                />
              </Tree>
              <Tree
                name={
                  <>
                    Hobbies <MdInterests style={{ color: "#00ffd0" }} />
                  </>
                }
              >
                <Tree name={<span>Tennis beginner 🎾 </span>} />
                <Tree name={<span>Snowboarding beginner 🏂</span>} />
                <Tree name={<span>Hiking hobbyist 🥾</span>} />
                <Tree name={<span>Music and Movies aficionado 🎵🎬</span>} />
                <Tree name={<span>Board game enthusiast 🎲</span>} />
                <Tree name={<span>Cats parent 🐱</span>} />
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: 200,
                    padding: 10,
                  }}
                >
                  <img
                    src="/images/luca&luna.png"
                    alt="Lucas and Luna"
                    style={{
                      width: "90%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                  />
                </div>
              </Tree>

              <Tree name="Thank you for visiting my website"></Tree>
            </Tree>
          </>
        )}
      </Tree>
    </Container>
  );
};

export default AboutList;

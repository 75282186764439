import { useEffect, useState } from "react";
import { restBase, PAGE_NAME } from "../global/globals";
import Loading from "../components/Loading";
import AboutList from "../components/AboutList";
import { Link } from "react-router-dom";
import Stacks from "../components/Stacks";
import Learning from "../components/Learning";
import CopyEmailButton from "../components/CopyEmailButton";

const About = () => {
  const restPath = restBase + "pages/15";
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);
  const emailAddr = "kaiasunofficial@gmail.com";

  useEffect(() => {
    document.title = `${PAGE_NAME} | About Me`;
    window.scrollTo(0, 0);
    fetch(restPath)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        // setLoadStatus(true);
        setTimeout(() => {
          setLoadStatus(true);
        }, 1600);
      });
  }, [restPath]);

  return (
    <>
      {isLoaded ? (
        <div id={`post-${restData.id}`} className="about">
          <Link to="/" className="logo-top" aria-label="Portfolio Logo">
            <img src="/images/portfolio-logo.png" alt="Portfolio Logo" />
          </Link>
          <h1>{restData.title.rendered}</h1>
          <AboutList isHomePage={false} isAboutPage={true} />
          {restData.acf && (
            <div className="skills">
              <div className="stacks">
                <h3>{restData.acf.developer_stack_heading}</h3>
                <Stacks restData={restData} />
              </div>
              <div className="learning">
                <h3>{restData.acf.currently_learning_heading}</h3>
                <Learning restData={restData} />
              </div>
            </div>
          )}
          <div className="contact">
            <CopyEmailButton email={emailAddr} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default About;

import React from "react";
import "./ScrollDown.css";

const ScrollDown = () => {
  return (
    <div className="scroll-container">
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
      <span className="text">Scroll down</span>
    </div>
  );
};

export default ScrollDown;

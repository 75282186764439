import { Link } from "react-router-dom";
import { featuredImage } from "../global/globals";
import MediaImage from "./MediaImage";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { truncate } from "../utilities/toolbelt";
import { useState } from "react";
import Accordion from "./Accordion";

const WorkItem = ({ work, showLink, showDetails, showToolkits, pageType }) => {
  AOS.init();
  const [expandedSections, setExpandedSections] = useState([]);

  const toggleSection = (index) => {
    if (expandedSections.includes(index)) {
      setExpandedSections(expandedSections.filter((i) => i !== index));
    } else {
      setExpandedSections([...expandedSections, index]);
    }
  };
  return (
    <LazyMotion features={domAnimation}>
      <m.article id={`work-${work.id}`} className={`work-item ${pageType}`}>
        {!showLink && work?.title?.rendered && <h1>{work.title.rendered}</h1>}
        {work.featured_media !== 0 && work._embedded && (
          <figure
            className="featured-image"
            dangerouslySetInnerHTML={{
              __html: `<img 
               src="${
                 featuredImage(work._embedded["wp:featuredmedia"][0]).src
               }" 
               alt="${work._embedded["wp:featuredmedia"][0].alt_text}" 
               srcset="${
                 featuredImage(work._embedded["wp:featuredmedia"][0]).srcSet
               }"
               
            />`,
            }}
          ></figure>
        )}
        <div className="work-details">
          {showLink && (
            <Link to={`/works/${work.slug}`}>
              <h2>{work.title.rendered}</h2>
            </Link>
          )}
          {!showLink && work?.title?.rendered && <h2>{work.title.rendered}</h2>}
          {showDetails && (
            <>
              <div>
                <p>{work?.acf?.overview}</p>
                <div className="buttons">
                  {work?.acf?.live_server && (
                    <div className="button-container">
                      <Link to={work.acf.live_server.url} target="_blank">
                        <button>Live Site</button>
                      </Link>
                    </div>
                  )}
                  {work?.acf?.github && (
                    <div className="button-container">
                      <Link to={work.acf.github.url} target="_blank">
                        <button>GitHub</button>
                      </Link>
                    </div>
                  )}
                </div>
                <Tabs>
                  <TabList>
                    {work?.acf?.toolkits && <Tab>Toolkits</Tab>}
                    {work?.acf?.roles && <Tab>Roles</Tab>}
                  </TabList>
                  <TabPanel>
                    {work?.acf?.toolkits && (
                      <div className="toolkits">
                        <ul>
                          {work.acf.toolkits.map((toolkit, index) => (
                            <li key={index}>{toolkit}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {work?.acf?.roles && (
                      <div className="roles">
                        <ul>
                          {work.acf.roles.map((role, index) => (
                            <li key={index}>{role}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
              <div>
                {work?.acf?.flexible &&
                  work.acf.flexible.map((item, index) => (
                    <div
                      className="work-content"
                      key={index}
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <h3>{item.feature_heading}</h3>
                      <div className="work-feature">
                        <h4>{item.design_highlights}</h4>
                        <div>
                          {item.design_content &&
                            item.design_content.map(
                              (contentItem, contentIndex) => (
                                <div
                                  className="feature-area"
                                  key={contentIndex}
                                >
                                  {contentItem.feature_image && (
                                    <MediaImage
                                      mediaID={contentItem.feature_image}
                                    />
                                  )}
                                  <div className="feature-content">
                                    {contentItem.feature_content_detail
                                      .split("\r\n")
                                      .map((paragraph, index) => (
                                        <p key={index}>{paragraph}</p>
                                      ))}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                        <h4>{item.functionality_highlights}</h4>
                        <div className="functionality">
                          {item.functionality_content &&
                            item.functionality_content.map(
                              (contentItem, contentIndex) => (
                                <div
                                  className="feature-area"
                                  key={contentIndex}
                                >
                                  {contentItem.feature_image && (
                                    <MediaImage
                                      mediaID={contentItem.feature_image}
                                    />
                                  )}
                                  <div className="feature-content">
                                    {contentItem.feature_content_detail
                                      .split("\r\n")
                                      .map((paragraph, index) => (
                                        <p key={index}>{paragraph}</p>
                                      ))}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  ))}

                <Accordion
                  sections={[
                    {
                      heading: work.acf.reflection_heading,
                      content: (
                        <ul className="reflection-content">
                          {work.acf.reflection.map((item, index) => (
                            <li key={index}>{item.reflections}</li>
                          ))}
                        </ul>
                      ),
                    },
                    {
                      heading: work.acf.improvement_ideas_heading,
                      content: (
                        <ul className="improvement-content">
                          {work.acf.improvement_ideas.map((item, index) => (
                            <li key={index}>{item.ideas}</li>
                          ))}
                        </ul>
                      ),
                    },
                  ]}
                  expanded={expandedSections}
                  setExpanded={toggleSection}
                />
              </div>
            </>
          )}
          {!showDetails && (
            <>
              {!showToolkits && <p>{work?.acf?.overview}</p>}
              {showToolkits && (
                <>
                  <p className="overview">
                    {truncate(work?.acf?.overview, 200)}
                  </p>
                  <div className="toolkits">
                    <ul>
                      {work.acf.toolkits.map((toolkit, index) => (
                        <li key={index}>{toolkit}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
          {showLink && (
            <div className="button-container">
              <Link to={`/works/${work.slug}`}>
                <button>VIEW PROJECT</button>
              </Link>
            </div>
          )}
        </div>
      </m.article>
    </LazyMotion>
  );
};
export default WorkItem;

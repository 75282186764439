import AnimatedLogo from "./AnimatedLogo";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-logo">
        <AnimatedLogo />
      </div>
    </div>
  );
};
export default Loading;

import React, { useState, useEffect } from "react";
import { motion, useMotionValue } from "framer-motion";
import Square from "./Square";
import { calculateGap } from "./Stacks";

const Learning = ({ restData }) => {
  const learningData = restData.acf.currently_learning;
  const gridSize = Math.ceil(Math.sqrt(learningData.length));
  const grid = Array.from({ length: gridSize }, (_, rowIndex) =>
    Array.from(
      { length: gridSize },
      (_, colIndex) => rowIndex * gridSize + colIndex
    )
  );

  const size = 100;
  const [active, setActive] = useState({ row: 0, col: 0 });
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const [gap, setGap] = useState(calculateGap());

  useEffect(() => {
    const handleResize = () => {
      setGap(calculateGap());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="learning">
      <motion.div
        animate={{ "--base-hue": 360 }}
        initial={{ "--base-hue": 0 }}
        transition={{ duration: 10, loop: Infinity, ease: "linear" }}
        style={{ width: "100%", height: "100%" }}
      >
        <motion.div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: (size + gap) * gridSize - gap,
            position: "relative",
            perspective: 500,
          }}
        >
          {grid.map((row, rowIndex) =>
            row.map((cellIndex) => {
              if (cellIndex < learningData.length) {
                return (
                  <Square
                    x={x}
                    y={y}
                    active={active}
                    setActive={setActive}
                    rowIndex={Math.floor(cellIndex / gridSize)}
                    colIndex={cellIndex % gridSize}
                    size={size}
                    gap={gap}
                    text={learningData[cellIndex]}
                    key={cellIndex}
                  />
                );
              } else {
                return null;
              }
            })
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Learning;

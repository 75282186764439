import React, { useState, useEffect } from "react";
import { restBase, featuredImage } from "../global/globals";

const MediaImage = ({ mediaID, size }) => {
  const restPath = restBase + `media/${mediaID}`;
  const [mediaData, setMediaData] = useState(null);

  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const response = await fetch(restPath);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setMediaData(data);
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };
    fetchMediaData();
  }, [restPath, mediaID]);

  useEffect(() => {
    if (!mediaData) {
      return;
    }

    const image = featuredImage(mediaData, size);

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.src = image.src;
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const imgElement = document.querySelector(`img[src="${image.src}"]`);
    if (imgElement) {
      observer.observe(imgElement);
    }

    return () => {
      if (imgElement) {
        observer.unobserve(imgElement);
      }
    };
  }, [mediaData, size]);

  if (!mediaData) {
    return null;
  }

  const image = featuredImage(mediaData, size);

  return (
    <figure>
      <img
        src={image.src}
        alt={mediaData.alt_text}
        width={image.width}
        height={image.height}
        srcSet={image.srcSet}
        sizes={image.sizes}
        loading="lazy"
      />
    </figure>
  );
};

export default MediaImage;

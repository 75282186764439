import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { restBase } from "../global/globals";
import Loading from "../components/Loading";
import { PAGE_NAME } from "../global/globals";
import WorkItem from "../components/WorkItem";
import { Link } from "react-router-dom";
import BlurGlass from "../components/BlurGlass";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Work = () => {
  const { slug } = useParams();
  const restPath = restBase + `portfolio-work?slug=${slug}&_embed`;
  const [restData, setData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    document.title = `${PAGE_NAME} | ${slug}`;
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();
        setData(data[0]);
        // setLoadStatus(true);
        setTimeout(() => {
          setLoadStatus(true);
        }, 1600);
      } else {
        setLoadStatus(false);
      }
    };

    const fetchOtherData = async () => {
      const response = await fetch(restBase + "portfolio-work?_embed");
      if (response.ok) {
        const data = await response.json();
        setOtherData(data.filter((work) => work.slug !== slug));
        setTimeout(() => {
          setLoadStatus(true);
        }, 1600);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();
    fetchOtherData();
  }, [slug, restPath]);

  const settings = {
    className: "works-center",
    centerMode: true,
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0",
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      {isLoaded ? (
        <>
          <Link to="/" className="logo-top" aria-label="Portfolio Logo">
            <img src="/images/portfolio-logo.png" alt="Portfolio Logo" />
          </Link>
          <section>
            {restData && (
              <WorkItem
                key={restData.id}
                work={restData}
                showLink={false}
                showDetails={true}
                pageType="single-work-page"
              />
            )}
          </section>
          <BlurGlass>
            <aside className="my-works">
              <h2>My Works</h2>
              <Slider {...settings}>
                {otherData &&
                  otherData.length > 0 &&
                  otherData.map((work) => (
                    <WorkItem
                      key={work.id}
                      work={work}
                      showLink={true}
                      showDetails={false}
                      showToolkits={false}
                      pageType="other-works-page"
                    />
                  ))}
              </Slider>
            </aside>
          </BlurGlass>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default Work;

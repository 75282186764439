import React, { useState } from "react";
import styled from "styled-components";
import { motion, useSpring } from "framer-motion";
import { distance } from "@popmotion/popcorn";

const StyledSquare = styled(motion.div)`
  width: 100px;
  height: 35px;
  position: absolute;
  border-radius: 10px;
  border: 1px solid #22e592;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.65rem, 1.15vw, 1.25rem);
  font-weight: bold;
  pointer-events: ${({ isDragging }) => (isDragging ? "none" : "auto")};
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: ${({ isDragging }) =>
    isDragging ? "#22e592" : "transparent"};

  &:hover {
    background-color: #22e592;
  }

  @media (min-width: 1024px) {
    padding: 14px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 8px;
  }
  @media (min-width: 425px) and (max-width: 768px) {
    padding: 6px;
  }
`;

const Square = ({ active, setActive, rowIndex, colIndex, x, y, gap, text }) => {
  const isDragging =
    active && colIndex === active.col && rowIndex === active.row;
  const d = distance(
    { x: active?.col || 0, y: active?.row || 0 },
    { x: colIndex, y: rowIndex }
  );
  const springConfig = {
    stiffness: Math.max(700 - d * 120, 0),
    damping: 20 + d * 5,
  };
  const dx = useSpring(x, springConfig);
  const dy = useSpring(y, springConfig);

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleUnhover = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setIsHovered(true);
  };

  return (
    <StyledSquare
      drag
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      dragTransition={{ bounceStiffness: 500, bounceDamping: 20 }}
      dragElastic={1}
      onDragStart={() => setActive({ row: rowIndex, col: colIndex })}
      onDragEnd={() => setActive(null)}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      onClick={handleClick}
      style={{
        top: `${rowIndex * (35 + gap)}px`,
        left: `${colIndex * (100 + gap)}px`,
        x: isDragging ? x : dx,
        y: isDragging ? y : dy,
        zIndex: isDragging ? 1 : 0,
      }}
    >
      {isDragging ? text : <div>{isHovered ? "Drag me" : text}</div>}
    </StyledSquare>
  );
};

export default Square;
